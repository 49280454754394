<template>
    <div>
      <el-drawer
        :visible="visible"
        v-bind="configDraw"
        @open="openDrawer"
        @close="closeDrawer"
      >
        <div class="h-full flex flex-col">
          <div class="flex-1 h-0 overflow-auto p-3">
            <slot></slot>
          </div>
          <div
            v-if="showBtn || btns"
            class="flex justify-end mt-4 bg-white p-3"
          >
            <el-button
              @click="onCancel"
              v-for="btn in btns"
              :key="index"
              v-bind="btn.config"
              v-on="btn.on"
              >{{ btn.text }}</el-button
            >
  
            <el-button
              v-if="showBtn"
              @click="onCancel"
              >取消</el-button
            >
            <el-button
              v-if="showBtn"
              type="primary"
              @click="onSure"
              >确定</el-button
            >
          </div>
        </div>
      </el-drawer>
    </div>
  </template>
  <script>
  /* btns  ({})[]
    text: string
    config: object
    on: object
    */
  export default {
    name: 'Drawer',
    props: {
      showBtn: Boolean,
      visible: Boolean,
      btns: Array,
      configDraw: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {};
    },
    methods: {
      openDrawer() {
        this.$emit('open');
      },
      closeDrawer() {
        this.$emit('update:visible', false);
        this.$emit('close');
      },
      onCancel() {
        // 如果没有传递取消事件，直接关闭弹框
        if (!this.$listeners.cancel) {
          this.closeDrawer();
        }
        this.$emit('cancel');
      },
      onSure() {
        this.$emit('sure');
      },
    },
    watch: {},
  };
  </script>
  