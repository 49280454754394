<template>
  <el-drawer
    title="操作日志"
    :visible="show"
    direction="rtl"
    :before-close="handleClose"
    :size="400"
    :modal="false"
    append-to-body
    @open="handleOpen"
  >
    <div class="operateWrap">
      <el-timeline v-infinite-scroll="load">
        <el-timeline-item
          v-for="(item, index) in operateContent"
          :key="index"
          color="#359efe"
          :timestamp="item.operateTime.replace('T', ' ')"
        >
          <span>{{ item.level == 'campaign' ? '广告系列' : '广告组' }}</span>
          <span>{{ operateType[item.operateType] }}</span>
          <span class="no-margin">{{ operateValue[item.oldValue] || item.oldValue }}</span>
          <i class="el-icon-right"></i>
          <span>{{ operateValue[item.newValue] || item.newValue }}</span>
          <div class="operate_source">
            <span>来源</span>
            <span>{{ item.source_text }}</span>
            <span>{{ item.operateUserId }}</span>
          </div>
        </el-timeline-item>
      </el-timeline>
      <div v-loading="loading"></div>
    </div>
  </el-drawer>
</template>

<script>
import moment from 'moment';
import { getMulLogList } from '../../serve/logDraw.js';
export default {
  name: 'logDraw',
  props: {
    needSign: Boolean,
    show: Boolean,
    apiParams: {
      type: Object,
      required: true,
      validator: (obj) => {
        if (!obj) return true;
        return obj.hasOwnProperty('platform') && obj.hasOwnProperty('level') && obj.hasOwnProperty('campaignId');
      },
    },
  },
  data() {
    return {
      operateType: {
        status: '状态',
        dailyBudget: '日预算',
        lifetimeBudget: '总预算',
        bidAmount: '竞价',
        creative: '创意',
        audience: '受众',
      },
      operateValue: {
        ACTIVE: '开启',
        ENABLE: '开启',
        PAUSED: '暂停',
      },
      operateContent: [],
      paging: {
        page_number: 1,
        page_size: 20,
        total_count: 0,
      },
      loading: false,
      loaded: false,
    };
  },
  methods: {
    //打开弹窗
    handleOpen() {
      this.loading = true;
      this.loaded = false;
      this.paging = {
        ...this.paging,
        page_number: 1,
      };
      this.operateContent = [];
      this.getList();
    },
    load() {
      if (this.loading || this.loaded) return;
      this.paging = {
        ...this.paging,
        page_number: this.paging.page_number + 1,
      };
      this.getList();
    },
    getList() {
      this.loading = true;
      let regularParams = {
        operateTimeStart: moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss'), //默认最近七天
        pageNumber: this.paging.page_number,
        pageSize: this.paging.page_size,
        platform: this.apiParams.platform,
        level: this.apiParams.level,
        campaignId: this.apiParams.campaignId,
        adGroupId: this.apiParams.adGroupId,
        adId: this.apiParams.adId,
      };
      getMulLogList(regularParams, this.needSign)
        .then((res) => {
          if (res.contents.length > 0) {
            if (res.contents.length < this.paging.page_size) {
              this.loaded = true;
            }
            res.contents.forEach((item) => {
              if (item.functionType === 'manual') {
                if (item.clientType === 'pc') {
                  item.source_text = 'PC电脑端';
                } else {
                  item.source_text = '手机端';
                }
              } else if (item.functionType === 'scan') {
                item.source_text = '自动扫描';
              } else if (item.functionType == 'auto') {
                item.source_text = `自动规则(${item.autoId})`;
              } else if (item.functionType == 'timer') {
                item.source_text = '定时操作';
              } else {
                item.source_text = '未知';
              }
            });
            this.operateContent.push(...res.contents);
            this.paging = res.paging;
          } else {
            if (this.paging.page_number == 1) {
              this.$message({ message: '最近7天没有操作日志可以查看哦~' });
              this.handleClose();
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    //关闭弹窗
    handleClose() {
      this.$emit('update:show', false);
    },
  },
};
</script>

<style lang="scss" scope>
.el-drawer__header {
  padding: 20px;
  margin-bottom: 0;
  background-color: #f9f9f9;
  border-bottom: 1px solid #e4e4e4;
}
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  background: #ededed;
}
.operateWrap {
  height: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
  .el-timeline {
    padding: 20px;
  }
  span {
    margin-right: 10px;
  }
  .no-margin {
    margin-right: 0;
  }
  .el-timeline {
    height: 100%;
    overflow-y: scroll;
  }
}
.el-timeline-item__tail {
  border-left: 2px solid #e7e7e7;
}
.el-timeline-item__node--normal {
  left: 0;
}
.operate_source {
  margin-top: 5px;
  span {
    margin-right: 20px;
  }
}
</style>
