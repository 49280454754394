<template>
  <el-input
    v-bind="$props"
    @input="onChange"
    @change="e => $emit('change', e)"
    @blur="e => $emit('blur', e)"
  ></el-input>
</template>
<script>
export default {
  props: {
    value: [Number, String],
    placeholder: String,
    fixed: Number,
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  methods: {
    onChange(val) {
      let reg = new RegExp(`^(\\d+)(\\.)(\\d{0,}).*$`);
      if (this.fixed) {
        reg = new RegExp(`^(\\d+)(\\.)(\\d{1,${this.fixed}}).*$`);
      }
      let s = reg.test(val);
      if (s) {
        //正则匹配通过，提取有效文本
        val = val.replace(reg, '$1$2$3');
      }
      console.log(val);
      this.$emit('change', val);
      this.$emit('input', val);
    },
  },
};
</script>
